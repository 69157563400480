<template>
  <table-view
    class="transfer-record"
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        keyword-placeholder="姓名、录取编号"
        :queryInfo.sync="queryInfo"
        :tool-list="['grade',  'keyword']"
        @on-search="renderTable(1)">
      </view-search-form>
      <div class="header-button">
        <el-button type="success" size="small" @click="exportMajorInfo"
        >导出数据
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%"
              border stripe>
      <el-table-column type="index" label="ID" width="60" align="center" />
      <el-table-column prop="examineNo" label="录取编号" width="150" />
      <el-table-column prop="stuName" label="姓名" width="120" />
      <el-table-column prop="sexType" label="性别" width="60" align="center">
        <template v-slot="{ row }">
          <span v-if="Number(row.sexType) === 1">男</span>
          <span v-else>女</span>
        </template>
      </el-table-column>
      <el-table-column prop="campusName" label="当前校区" width="100" align="center" />
      <el-table-column label="原专业类型" width="100" align="center">
        <template v-slot="{ row }">
          {{ $http.getParamName(row.fromMajorType, $store.state.systemParam.majorType) }}
        </template>
      </el-table-column>
      <el-table-column prop="fromMajorName" label="原专业" width="150" />
      <el-table-column label="现专业类型" width="100" align="center">
        <template v-slot="{ row }">
          {{ $http.getParamName(row.toMajorType, $store.state.systemParam.majorType) }}
        </template>
      </el-table-column>
      <el-table-column label="现专业" width="200" align="center">
        <template v-slot="{ row }">
          <el-tag>{{ row.toMajorName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="学籍状态" width="120">
        <template v-slot="{ row }">
          {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="className" label="班级" width="150">
        <template v-slot="{ row }">
          {{ row.className || '未绑定' }}
        </template>
      </el-table-column>
      <el-table-column label="操作时间" width="160" align="center" fixed="right">
        <template v-slot="{ row }">
          {{ $moment(row.createTime).format('YYYY-MM-DD HH:mm') }}
        </template>
      </el-table-column>
    </el-table>
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { pageMajorTransferRecordApi } from '@/api/student-manage/major-transfer-api'

export default {
  name: 'transferRecord',
  components: {},
  mixins: [tableView],
  data() {
    return {
      queryInfo: {
        grade: new Date().getFullYear(),
        changeMajorFlag: null,
        campusId: null,
        stuStatus: 1
      },
      stuInfo: []
    }
  },
  methods: {
    // 获取学生列表
    async renderTable(pageNum) {
      await this.getTableData(pageMajorTransferRecordApi, pageNum)
    },
    //   批量导出
    async exportMajorInfo() {
      this.$http.exportExcelHttp
        .exportMajorTransferRecord({ grade: this.queryInfo.grade })
        .then((res) => {
          this.$message.success('导出成功!')
        })
    }
  }
}
</script>
